.recap-content{
    margin-top:20px;
    background-color: #f1f3f7;
}

.avatar-lg {
    height: 4rem;
    width: 4rem;
}

.font-size-16 {
    font-size: 16px!important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

a {
    text-decoration: none!important;
}

.w-xl {
    min-width: 160px;
}

.card {
    margin-bottom: 24px;
    -webkit-box-shadow: 0 2px 3px #e4e8f0;
    box-shadow: 0 2px 3px #e4e8f0;
}

