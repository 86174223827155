.container {
    max-width: 950px;
    margin: 30px auto;
    background-color: #e8eaf6;
    padding: 35px;
}

@page {
    size: 2.8in 11in;
    margin-top: 0cm;
    margin-left: 0cm;
    margin-right: 0cm;
}

.box-left {
    padding: 10px 10px;
    background-color: #FFF;
    border-radius: 15px;
    margin: 0 auto;
    width: 80mm;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.logo-container{
    background-color: #fff;
}

.logo{
    height: 80px;
	width: 80px;
	background: url(../../../../../assets/images/logo.png) no-repeat;
	background-size: 80px 80px;
    margin-bottom: 10px;
}

.image-text{
    display: flex;
    align-items: center;
    font-family: 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
}

.h7{
    font-weight: bold;
    font-size: 16px;
    font-family: 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
}

.h8 {
    font-size: 15px;
    font-weight: 1000;
    font-family:  'Helvetica Neue', 'Helvetica', Arial, sans-serif;
}

@media print {
    .h7{
        font-weight: bold;
        font-size: 16px;
        font-family: 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
    }

    .h8 {
        font-size: 15px;
        font-weight : bold;
        font-weight: 1000;
        font-family: Arial;
    }

    .box-left {
        height: 100%;
        width: 80mm;
        margin: 0;
        padding: 10px;
    }
    
    .receipt-table{
        width: 100%;
        border-collapse: collapse;
    }
}

@media(max-width:320px) {
    .h8 {
        font-size: 14px;
        font-weight : bold;
        font-weight: 1000;
        font-family: Arial;
    }

}