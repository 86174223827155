
.badge-soft-danger {
  color: #f56e6e !important;
  background-color: rgba(245,110,110,.1);
}
.badge-soft-success {
  color: #63ad6f !important;
  background-color: rgba(99,173,111,.1);
}
.badge span {
  width: 60px;
  height: 25px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center
}

.image-text{
  display: flex;
  align-items: center;
}

.logo-text{
  display: flex;
  flex-direction: column;
}

.image-text .name {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 600;
  margin-left : 5px;
  margin-right : 20px;
}

.logo{
  width : 25px;
  height : 25px;
}

.cashRegister-content{
  border : 1px solid #000039
}

.cashRegister-title{
  background-color : #000039;
  font-family: cursive;
}

.cashRegister-number{
  color : #000039
}

.stats{

  background: #f2f5f8 !important;

color: #000 !important;
}
.cashRegisters{
  font-size:10px;
  color: #a1aab9;
}
.number1{
font-weight:500;
}
.followers{
font-size:10px;
color: #a1aab9;

}
.number2{
font-weight:500;
}
.rating{
font-size:10px;
color: #a1aab9;
}
.number3{
font-weight:500;
}
