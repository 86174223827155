.image-text{
    display: flex;
    align-items: center;
}

.logo-text{
    display: flex;
    flex-direction: column;
}

.image-text .name {
    margin-top: 5px;
    font-size: 20px;
    font-weight: 600;
    margin-left : 5px;
    margin-right : 20px;
}

.logo{
    width : 50px;
    height : 50px;
}

.client-content{
    border : 1px solid #000039
}

.client-title{
    background-color : #000039;
    font-family: cursive;
}

.client-number{
    color : #000039
}

.stats{

    background: #f2f5f8 !important;

  color: #000 !important;
}
.articles{
    font-size:10px;
    color: #a1aab9;
}
.number1{
font-weight:500;
}
.followers{
  font-size:10px;
color: #a1aab9;

}
.number2{
font-weight:500;
}
.rating{
  font-size:10px;
color: #a1aab9;
}
.number3{
font-weight:500;
}