.costing-container .costing-title{
    background-color : #000039;
}


.costing-container .stats{
    background: #f2f5f8 !important;
    color: #000 !important;
}

.costing-container .articles{
    font-size:10px;
    color: #a1aab9;
}

.costing-container .number1{
    font-weight: bold;
    font-size: 18px;
}

.costing-container .followers{
    font-size:10px;
    color: #a1aab9;
}

.costing-container .number2{
    font-weight:500;
}

.costing-container .rating{
    font-size:10px;
    color: #a1aab9;
}

.costing-container .number3{
    font-weight:500;
}
