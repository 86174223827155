.invoice-container {
    padding: 1rem;
  }
  .invoice-container .invoice-header .invoice-logo {
    margin: 0.8rem 0 0 0;
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 700;
    color: #2e323c;
    font-family: 'Georgia';
  }
  .invoice-container .invoice-header .invoice-logo img {
    max-width: 130px;
  }
  .invoice-container .invoice-header address {
    font-size: 0.8rem;
    margin: 0;
  }
  .invoice-container .invoice-details {
    margin: 1rem 0 0 0;
    line-height: 180%;
    background: #000039;
  }
  .invoice-container .invoice-details .invoice-num {
    text-align: right;
    font-size: 0.8rem;
  }
  .invoice-container .invoice-body {
    padding: 1rem 0 0 0;
  }
  .invoice-container .invoice-footer {
    text-align: center;
    font-size: 0.7rem;
    margin: 5px 0 0 0;
  }
  
  .invoice-status {
    text-align: center;
    padding: 1rem;
    background: #ffffff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  .invoice-status h2.status {
    margin: 0 0 0.8rem 0;
  }
  .invoice-status h5.status-title {
    margin: 0 0 0.8rem 0;
    color: #9fa8b9;
  }
  .invoice-status p.status-type {
    margin: 0.5rem 0 0 0;
    padding: 0;
    line-height: 150%;
  }
  .invoice-status i {
    font-size: 1.5rem;
    margin: 0 0 1rem 0;
    display: inline-block;
    padding: 1rem;
    background: #f5f6fa;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
  }
  .invoice-status .badge {
    text-transform: uppercase;
  }
  
  @media (max-width: 767px) {
    .invoice-container {
        padding: 1rem;
    }
  }
  
  
  .custom-table {
    border: 1px solid #e0e3ec;
  }
  .custom-table thead {
    background: #007ae1;
  }
  .custom-table thead th {
    border: 0;
    color: #0c0b0b;
  }
  .custom-table > tbody tr:hover {
    background: #fafafa;
  }
  .custom-table > tbody tr:nth-of-type(even) {
    background-color: #ffffff;
  }
  .custom-table > tbody td {
    border: 1px solid #e6e9f0;
  }
  
  
  .card {
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 0;
    margin-bottom: 1rem;
  }
  
  .text-success {
    color: #00bb42 !important;
  }
  
  .text-muted {
    color: #9fa8b9 !important;
  }
  
  .custom-actions-btns {
    margin: auto;
    display: flex;
    justify-content: flex-end;
  }
  
  .custom-actions-btns .btn {
    margin: .3rem 0 .3rem .3rem;
  }
  
  .image-text{
    display: flex;
    align-items: center;
}

.logo-text{
    display: flex;
    flex-direction: column;
}

.image-text .name {
    margin-top: 5px;
    font-size: 20px;
    font-weight: 600;
    margin-left : 5px;
    margin-right : 20px;
}

.logo{
    width : 50px;
    height : 50px;
}