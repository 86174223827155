
/* Import Google font - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

.user-role{
  font-size: 12px;
}
.profile-img{
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 5px;
}

.app-logo{
  height: 70px;
  width: 70px;
}

.menu_container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-left:0;
  overflow-y: auto;
  height: calc(100% - 82px);
}



.group_title{
  font-size : 11px;
}

.menu_title .line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  width: 20px;
  border-radius: 25px;
  background-color:#4070f4 ;
  transition: all 0.3s ease;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.menu_title .line {
  opacity: 0;
}

.item {
  list-style: none;
}

.link {
  text-decoration: none;
  border-radius: 8px;
  margin-bottom: 2px;
  color: #000;
  font-size:15px;
}

.link:hover {
  color: #000;
  background-color: #4070f4;
}
.link span {
  white-space: nowrap;
}
.link i {
  height: 45px;
  min-width: 30px;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.flex {
  display: flex;
  align-items: center;
}

/*================SIDEBAR HEADER ======================*/ 
.sidebar-header{
  position: relative;
}

.sidebar-header .image-text{
  display: flex;
  align-items: center;
}
.sidebar-header .logo-text{
  display: flex;
  flex-direction: column;
}
.sidebar-header .image-text .name {
  margin-top: 5px;
  font-size: 30px;
  font-weight: 900;
  margin-left : 10px;
  margin-right : 20px;
}

.sidebar-header .image-text .profession{
  font-size: 16px;
  margin-top: -2px;
  display: block;
}

.sidebar-header .image{
  min-width: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.sidebar-header .image img{
  width: 70px;
  border-radius: 6px;
}
