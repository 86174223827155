.image-text{
    display: flex;
    align-items: center;
}

.logo-text{
    display: flex;
    flex-direction: column;
}

.image-text .name {
    margin-top: 5px;
    font-size: 20px;
    font-weight: 600;
    margin-left : 5px;
    margin-right : 20px;
}

.logo{
    width : 50px;
    height : 50px;
}

.recipe-content{
    border : 1px solid #000039
}

.recipe-title{
    background-color : #000039;
    font-family: cursive;
}

.recipe-number{
    color : #000039
}